<template>
  <DefaultLayout class="frontend">
    <login class="max-w-[79rem] p-12 mx-auto my-16 bg-white" />
  </DefaultLayout>
</template>

<script>
import Login from '@/components/Login.vue';

export default {
  name: 'LoginView',
  components: {
    Login,
  },
};
</script>
