<template>
  <div>
    <two-factor-wizard
      v-if="show2FAWizard"
      :settings="twoFactorSettings"
      @close="goToDashboard"
    />
    <form v-else-if="!show2FA">
      <h2 v-if="!isLoginAfter2FASetup" class="mb-10 text-4xl leading-tight">
        {{ $t('login.title') }}
      </h2>
      <h2 v-else class="mb-10 text-4xl">{{ $t('login.title') }}</h2>
      <span v-if="errorMessage" class="block mt-4 text-primary-500">
        {{ errorMessage }}
      </span>
      <label-field label="E-Mail" class="mt-4" is-block :label-class="[]">
        <input
          v-model.trim="$v.email.$model"
          required
          class="w-[26.25rem] form-input"
          @blur="$v.email.$touch"
        />
        <validation-text v-if="$v.email.$error" class="mt-1">
          {{ $t('login.emailRequired') }}
        </validation-text>
      </label-field>
      <forge-password
        v-model.trim.lazy="$v.password.$model"
        class="w-full mt-4"
        class-input="w-full"
        :value="password"
        :label="$t('login.password')"
        :placeholder="$t('login.password')"
        is-block
        required
        @blur="$v.password.$touch"
      >
        <validation-text v-if="$v.password.$error" class="mt-1">
          {{ $t('login.passwordRequired') }}
        </validation-text>
      </forge-password>
      <div class="flex items-center justify-between mt-6">
        <button
          class="btn btn--primary"
          :disabled="$v.$invalid"
          @click.prevent="doLogin"
        >
          {{ $t('login.submitButtonText') }}
        </button>
      </div>

      <br />
      <router-link class="text-blue-primary" :to="{ name: 'forgot-password' }">
        {{ $t('login.forgotPassword') }}
      </router-link>
    </form>
    <form v-else class="max-w-lg">
      <h2 class="mb-10 text-4xl">{{ $t('login.twoFactorTitle') }}</h2>
      <ol class="mb-10 list-disc list-inside">
        <li>{{ $t('login.twoFactorStep1') }}</li>
        <li>{{ $t('login.twoFactorStep2') }}</li>
      </ol>
      <label-field class="mb-10" :label="$t('login.twoFactorLabel')" is-block>
        <input v-model="twoFactorToken" autofocus class="w-full form-input" />
        <validation-text v-if="tokenError" class="mt-1">
          {{ $t('login.twoFactorTokenWrong') }}
        </validation-text>
      </label-field>

      <button class="px-12 mb-8 btn btn--primary" @click.prevent="doLogin">
        {{ $t('login.next') }}
      </button>

      <a class="block cursor-pointer" @click="backToLogin">
        {{ $t('login.backToLogin') }}
      </a>
    </form>
  </div>
</template>

<script>
import LabelField from '@/components/LabelField.vue';
import ForgePassword from '@/components/Password.vue';
import ValidationText from '@/components/ValidationText.vue';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { login } from '@/services/auth.js';
import TwoFactorWizard from './twofactor/TwoFactorWizard.vue';

export default {
  components: {
    LabelField,
    ForgePassword,
    ValidationText,
    TwoFactorWizard,
  },
  data() {
    return {
      errorMessage: '',
      email: '',
      password: '',
      show2FA: false,
      twoFactorToken: '',
      show2FAWizard: false,
      twoFactorSettings: null,
      tokenError: false,
      isLoginAfter2FASetup: false,
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    nextDisabled() {
      // The token has a length of 6
      return this.twoFactorToken.length !== 6;
    },
  },
  methods: {
    ...mapActions('Auth', ['setUser']),
    async doLogin() {
      try {
        const response = await login(
          this.email,
          this.password,
          this.twoFactorToken
        );

        if (response.status === 204) {
          this.show2FA = true;
          return;
        }

        if (response.data.url !== undefined) {
          this.show2FAWizard = true;
          this.twoFactorSettings = { ...response.data, user: this.email };
          return;
        }
        const loginData = response.data;
        this.errorMessage = '';
        this.setUser(loginData);
        this.$store.dispatch('Block/loadCreators');
        this.$store.dispatch('Block/loadTypesOfLaw');
        this.$store.dispatch('Favorite/loadFavorites');
        this.goToDashboard();
      } catch (e) {
        if (e.response?.data === 'twofactor') {
          this.errorMessage = this.$t('login.twoFactorError');
        } else {
          this.errorMessage = this.$t('login.error');
        }
        if (e.response?.data?.detail === 'otp token is not verified') {
          this.tokenError = true;
        }
      }
    },
    backToLogin() {
      this.$v.$reset();
      this.show2FA = false;
      this.twoFactorToken = '';
      this.email = '';
      this.password = '';
      this.errorMessage = '';
    },
    goToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>
